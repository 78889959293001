<template>
  <vac-card :title="$t('Users groups')">

    <div class="row">
      <div class="flex md6 xs12">
        <router-link to="/panel/groups/add">
          <va-button>
            Add new group
          </va-button>
        </router-link>
      </div>

      <div class="flex xs12 md3 offset--md3">
        <va-select
          v-model="perPage"
          :label="$t('tables.perPage')"
          :options="perPageOptions"
          noClear
        />
      </div>

      <div class="flex xs12 md3">
        <va-input
                label=""
                placeholder="Search user groups"
                v-model.trim="searchValue"
                class="input--border"
                removable
        />
      </div>
    </div>

    <va-data-table
      :fields="fields"
      :data="getFilteredUsers"
      :per-page="perPage"
      :style="{marginTop: '24px'}"
    >

      <template slot="retailers" slot-scope="props">
        <p class="table-tag" v-for="retailer in props.rowData.retailers" v-bind:key="retailer.name">
          {{ retailer.name }}
        </p>
      </template>

      <template slot="brands" slot-scope="props">
        <p class="table-tag" v-for="brand in props.rowData.brands" v-bind:key="brand.name">{{ brand.name }}</p>
      </template>

      <template slot="actions" slot-scope="props">

        <a class="table-action" :href="`/panel/groups/edit/${props.rowData.id}/`">
          <i class="fa fa-pencil"></i>
        </a>

        <div class="table-action" @click="showDeleteModal = true; deleteID = props.rowData.id">
          <i class="fa fa-trash"></i>
        </div>

        <!--<va-popover :message="`${$t('tables.edit')} ${props.rowData.fullName}`" placement="top">-->
        <!--<va-button flat small color="gray" icon="fa fa-pencil" :href="`/panel/users/edit/${props.rowData.id}/`" />-->
        <!--</va-popover>-->

        <!--<va-popover :message="`${$t('tables.delete')} user`" placement="top">-->
        <!--<va-button flat small color="gray" icon="fa fa-trash" @click="showDeleteModal = true; deleteID = props.rowData.id" />-->
        <!--</va-popover>-->

      </template>

    </va-data-table>

    <va-modal
      v-model="showDeleteModal"
      size="small"
      :title=" $t('Delete user group?')"
      :message=" $t('If you delete user group then you will can\'t recover him.') "
      :okText=" $t('modal.confirm') "
      :cancelText=" $t('modal.cancel')"
      @ok="deleteGroup"
    />
    <va-progress-bar v-if="loading"
              indeterminate
              :style="{width: '100%', position: 'absolute', bottom: 0, left: 0, backgroundColor: '#ffffff'}"
    ></va-progress-bar>
  </vac-card>
</template>

<script>
  import VaButton from 'vuestic-ui/src/components/vuestic-components/va-button/VaButton';
  import API from '../../services/API/API';

  export default {
    name: "Groups",
    components: { VaButton },
    data() {
      return {
        searchValue: '',
        showDeleteModal: false,
        deleteID: null,
        perPageOptions: ['100', '200', '500', '1000'],
        perPage: 100,
        groups: [],
        loading: true,
      };
    },

    created() {
      API.APIGet(`${process.env.VUE_APP_API_URL}/statistics-group`, {}, (data) => {
        this.groups = data;
        this.loading =  false;
      });
    },

    computed: {
      getFilteredUsers () {
        if (this.searchValue) {
          return this.groups.filter(i => {
            return (
                i.id.toUpperCase().includes(this.searchValue.toUpperCase()) ||
                i.name.toUpperCase().includes(this.searchValue.toUpperCase())
            )
          })
        } else {
          return this.groups
        }
      },
      fields() {
        return [
          {
            name: 'id',
            title: 'ID',
            sortField: 'id',
            width: '30%',
          },
          {
            name: 'name',
            title: 'Name',
            sortField: 'name',
            width: '63%',
          },
          // {
          //   title: 'Retailers',
          //   name: '__slot:retailers',
          //   dataClass: 'td--no-break',
          // },
          // {
          //   title: 'Brands',
          //   name: '__slot:brands',
          //   dataClass: 'td--no-break',
          // },
          {
            name: '__slot:actions',
            dataClass: 'td--no-break',
          },
        ];
      },
    },

    methods: {

      deleteGroup() {
        API.APIDelete(`${process.env.VUE_APP_API_URL}/statistics-group/${this.deleteID}`, {}, () => {
          this.groups = this.groups.filter(group => group.id !== this.deleteID);
        });
      },

      getStarMessage(group) {
        const actionName = group.starred ? this.$t('tables.unstar') : this.$t('tables.star');
        return `${actionName} ${group.fullName}`;
      },

      getStarColor(group) {
        return group.starred ? 'primary' : 'gray';
      },

      star({ id }) {
        const i = this.groups.findIndex(group => group.id === id);
        this.groups[i].starred = !this.groups[i].starred;
      },

    },
  };
</script>

<style lang="scss">
  .inner-loading .va-table td.td--no-break {
    word-break: normal;
  }
</style>
